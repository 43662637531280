.main-footer {
	.about a {
		color: #af9169 !important;
		text-decoration: none !important;
		max-width: 250px;
		display: block;
		width: 100%;
	}

	.about a:hover {
		color: #000 !important;
		text-decoration: none !important;
	}

	.about a:hover {
		color: #1e5a50 !important;
	}

	.about .f-c-1 {
		color: #1e5a50;
		font-style: italic;
		font-family: 'EB Garamond', serif;
	}

	.about .f-c-2 {
		color: #1e5a50;
	}

	.about svg .st0 {
		fill: #af9169;
	}

	.about svg .st1 {
		fill: #1e5a50;
	}

	.footer-top {

		background: var(--Lightgreen);
		padding: 50px 0 15px 0;

		.widget {
			.about {
				padding: 0 0 30px 0;
			}

			h3 {
				font-weight: 500;
				letter-spacing: .5px;
				font-size: 20px;
				line-height: 1.1em;
				margin: 0;
				padding: 0 0 15px;
				display: flex;
				width: 100%;
				color: var(--Darkgreen);
			}

			.useful_links {
				padding: 0;
				padding-top: 15px;
				list-style: none;
				margin: 0 0 30px 0;

				li {
					padding: 0;
					font-size: 18px;
					font-weight: 400;
					margin: 0 0 10px 0;
					display: flex;

					a {
						color: var(--Themelight);
						-webkit-transition: all 0.2s ease-out;
						transition: all 0.2s ease-out;
						text-decoration: none;

						&:before {
							font-family: FontAwesome;
							content: "\f105";
							margin-right: 12px;
							color: var(--Themelight);
							display: block;
							float: left;
							font-size: 0.8em;
							line-height: 1.8em;
						}

						&:hover {
							color: var(--Darkgreen);
						}
					}
				}
			}
		}

		.address_details {
			padding: 15px 0 0px 0;
			list-style: none;
			margin: 0;

			li {
				padding: 0 0 0 20px;
				font-size: 18px;
				font-weight: 400;
				position: relative;
				margin: 0 0 10px;
				color: var(--Themelight);
				line-height: 24px;

				i {
					margin-right: 12px;
					font-size: 16px;
					color: var(--Themelight);
					position: absolute;
					left: 0;
					top: 5px;
				}

				a {
					color: var(--Primarycolor);
					text-decoration: none;

					&:hover {
						color: var(--Darkgreen);
					}
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					display: flex;
					align-items: center;
				}
			}

		}

		.social-bl {
			display: flex;
			align-items: center;
			margin: 10px 0 0 0;

			a {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--Primarycolor);
				color: #fff;
				border-radius: 50%;
				margin-right: 10px;
				text-decoration: none;
			}
		}
	}

	.copyright {
		padding: 30px 0px;
		background: var(--whitecolor);

		p {
			font-size: 17px;
			margin: 0;
			padding: 0;
			color: #777777;
			font-weight: 500;

			a {
				color: var(--PrimaryColor);
				text-decoration: none;
			}

			@media (max-width: 767px) {
				text-align: center;
			}
		}
	}

	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;


		}


		.footer-link {
			color: #888;
		}


	}
}