@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.carousel-default {
		position: relative;

		.owl-item {
			.item {
				img {
					height: 700px;
					object-fit: cover;
				}
			}
		}

		.owl-nav {
			display: none;
		}

		.owl-dots {
			display: none;
		}
	}

	.iheader {

		min-height: 500px;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}



	.searchbar {
		padding: 0;
		margin: 0;
		position: absolute;
		width: 100%;
		top: 170px;

		.container {
			background: rgb(0 0 0 / 0.4);
			padding: 20px;
			border-radius: 0;

			.row {
				margin: 0;
				display: flex;
				align-items: center;
				flex-wrap: wrap;


				.searchbox {
					label {
						font-weight: 400;
						margin: 0;
						font-size: 18px;

						&.white {
							color: var(--white);
						}
					}

					.form-control {
						border-radius: 0;
						font-size: 18px;
						height: 45px;
						font-weight: 400;
						border: none;
					}

					.search-btn {
						background: var(--Primarycolor);
						color: #fff;
						box-shadow: none;
						border-radius: 0;
						text-transform: uppercase;
						letter-spacing: 0.5px;
						font-weight: 400;
						border: 2px solid var(--Primarycolor);
						height: 45px;
						font-size: 18px;
						line-height: 18px;

						&:hover {
							background: transparent;
						}
					}

					.warning-message {
						padding: 5px 5px;
						font-size: 14px;
						width: 100%;
						display: block;
						text-align: center;
						margin: 0;
						border-radius: 0px;
					}
				}
			}
		}

		.searchbox {
			padding: 5px;

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

		@media (min-width:768px) {
			max-width: 100%;
			z-index: 1;
		}

		@media (min-width:993px) {
			max-width: 100%;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}
		}

		.searchbar {
			padding: 0 10px;

			.container {
				border-radius: 30px;
			}
		}
	}

}

@media (max-width: 992px) {
	.indexpage {
		.carousel-default {
			.owl-carousel {
				z-index: -1;
			}

			.owl-item {
				.item {
					img {
						height: 450px;
					}
				}
			}
		}
	}
}