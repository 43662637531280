@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

a {
	text-decoration: none !important;
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.navbarheader {
		position: relative;
		margin: 0;
		background: var(--Primarycolor);
	}

	.navbarheader.affix {
		position: fixed;
		top: 0
	}

	.result-pane {
		width: 100%;
		padding: 0;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			@media all and (min-width: 768px) and (max-width: 991px) {
				min-height: auto;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				min-height: auto;
			}

			img {
				max-width: 100%;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel {
		background-color: #f5f5f5;
	}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;

			padding: 5px;
			padding-bottom: 15px;

			.map-marker {
				position: absolute;
				top: 15px;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: var(--Primarycolor);
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--Primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						height: 35px !important;
						text-align: center;
						z-index: 1;
					}
				}
			}

			.alternatives {
				background: #fff;
				padding: 10px 10px 0 10px;
				border: 1px solid var(--Primarycolor);
				margin: 0 0 20px 0;

				ul {
					padding: 10px 0 10px 20px;
				}
			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}
			}
		}
	}


	.search-result-properties {
		display: inline-block;

		>div {
			float: left;
			text-align: center;
			border-right: 1px solid #ccc;
			background-color: rgb(202 171 115 / 0.7);
			padding: 5px 10px;
			color: #fff;
			border: 1px solid var(--Primarycolor);
			margin-right: 5px;
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			justify-content: center;

			i {
				margin: 0 5px 0 0;
			}
		}

	}



	.search-bar {
		padding: 0px;

		.daterange {
			display: inline-block;
		}

		.btn {
			margin: 2px;
		}

		.search-left-top {
			overflow: visible;
			margin-bottom: 0;
			padding: 20px;
			background-color: var(--Primarycolor);

			h4 {
				margin: 0 0 10px;
				padding: 0;
				font-size: 24px;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1px;
				background: transparent;
				font-family: Barlow Condensed, sans-serif;
				font-weight: 400;
			}

			.datepicker-trigger {
				width: 100%;
				margin: 0 0 4px 0;

				#datepicker-trigger {
					margin: 0;
					width: 100%;
					border-radius: 0;
					height: 46px;
					padding: 0 15px;
					border: 1px solid #ccc;
					color: #47403a;
					font-size: 18px;
					width: 100%;
					text-align: left;
				}
			}

			.guest-trigger-main {
				width: 100%;
				margin: 0 0 4px 0;

				#guest-trigger {
					margin: 0;
					width: 100%;
					border-radius: 0;
					height: 46px;
					padding: 0 15px;
					border: 1px solid #ccc;
					color: #47403a;
					font-size: 17px;
					width: 100%;
					text-align: left;
				}
			}

			.form-group {
				.checkbox {
					input {}

					label {
						color: #fff;
						font-size: 18px;
						line-height: 20px;

						&:before {
							border: 1px solid #fff !important;
							border-radius: 0;
							background-color: transparent;
							outline: none;
							margin-top: 3px;
						}

						&:after {
							margin-top: 2px;
						}
					}
				}

				.checkbox-success input[type=checkbox]:checked+label:before {
					background-color: var(--Primarycolor);
					border-color: var(--Primarycolor);
				}
			}
		}

		.panel {
			margin-bottom: 20px;
			padding: 20px 20px 15px 20px;
			border-right: 5px solid #efefef;
			border-bottom: 5px solid #efefef;
			border-left: 5px solid #efefef;

			h3 {
				margin-top: 0;
				margin-bottom: 5px;
				margin-left: 0;
				padding: 0 0 4px;
				border-bottom: 1px solid #424242;
				font-size: 1.5rem;
				color: #424242;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: 400;
				font-family: Barlow Condensed, sans-serif;
			}

			.panel-body {
				padding: 0;

				.adv-filters {
					margin-top: 10px;
				}

				.col-sm-4 {
					width: 100%;
				}

				.form-control {
					margin: 0 0 5px;
					width: 100%;
					border-radius: 0;
					height: 46px;
					padding: 0 15px;
					border: 1px solid #ccc;
					color: #47403a;
					font-size: 17px;
					width: 100%;
					text-align: left;

					&.mb-12 {
						margin-bottom: 12px;
					}
				}

				.form-group {
					margin: 0;

					.checkbox {
						margin: 0 0 4px 0;

						label {
							color: var(--Primarycolor);
							font-size: 15px;
							color: #666;

							&:before {
								border: 1px solid var(--Primarycolor);
								border-radius: 0;
								background-color: transparent;
								outline: none;
								margin-top: 2px;
							}

							&:after {
								margin-top: 2px;
							}
						}
					}
				}

				.checkbox-success input[type=checkbox]:checked+label:before {
					background-color: var(--Primarycolor);
					border-color: var(--Primarycolor);
				}
			}
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}

		.reset-filter {
			a {
				text-align: center;
				background-color: #fff;
				border: 1px solid #af9169;
				border: 1px solid var(--Themelight);
				border-radius: 2px;
				line-height: 38px;
				color: var(--Themelight);
				padding: 8px 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				font-size: 18px;
				letter-spacing: .05rem;

				&:hover {
					color: var(--Reetlandred) !important;
					border: 1px solid var(--Reetlandred);
				}
			}
		}
	}

	.search-list-section {
		.result-pane {
			.result-header {
				h3 {
					margin: 0 0 20px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: space-between;
					width: 100%;
					font-size: 2.5rem;
					font-family: Barlow Condensed, sans-serif;
					color: var(--Themelight);
					font-weight: 600;
					letter-spacing: .2rem;
					text-transform: uppercase;

					.sorting-trigger {
						text-align: center;
						background-color: #fff;
						border: 1px solid var(--Themelight);
						border-radius: 2px;
						line-height: 38px;
						color: var(--Themelight);
						padding: 8px 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 48px;
						font-size: 18px;
						letter-spacing: 0.05rem;

						&:hover {
							color: var(--Reetlandred) !important;
							border: 1px solid var(--Reetlandred);
						}
					}
				}
			}

			.results {
				background-color: #fff;

				.srow {
					margin: 0 0 30px 0;
					padding: 0;

					a {
						text-decoration: none !important;
					}

					.img-col {
						.img-content {}

						.owl-carousel {
							overflow: hidden;
							height: 100%;

							.owl-stage-outer {
								height: 100%;

								.owl-stage {
									height: 100%;

									.owl-item {
										height: 100%;

										.item {
											height: 100%;
										}
									}
								}
							}

							.owl-nav {
								position: absolute;
								top: 50%;
								left: 0;
								width: 100%;
								z-index: 99;

								.owl-prev {
									width: 40px;
									height: 40px;
									position: absolute;
									left: 0;
									outline: none;
									display: flex;
									-webkit-box-align: center;
									align-items: center;
									-webkit-box-pack: center;
									justify-content: center;
									color: #424242;
									z-index: 99;
									top: -50%;
									position: absolute;

									span {
										font-size: 50px;
										color: #fff;
									}
								}

								.owl-next {
									width: 40px;
									height: 40px;
									position: absolute;
									right: 0;
									outline: none;
									display: flex;
									-webkit-box-align: center;
									align-items: center;
									-webkit-box-pack: center;
									justify-content: center;
									color: #424242;
									z-index: 99;
									top: -50%;
									position: absolute;

									span {
										font-size: 50px;
										color: #fff;
									}
								}
							}
						}
					}

					.info-col {
						padding: 20px 30px 30px;
						background-color: #f5f5f5;
						padding: 20px 30px 30px;
						background-color: #f5f5f5;
						border-radius: 0;
						box-shadow: none;

						h4 {
							font-size: 24px;
							width: 100%;
							line-height: 28px;
							white-space: normal;
							font-family: Barlow Condensed, sans-serif;
							color: var(--Themelight);
							margin: 0;
							padding-right: 15px;

						}

						h6 {
							font-size: 15px;
							line-height: 22px;
							color: var(--Darkgreen);
							font-family: Barlow Condensed, sans-serif;
							margin: 0 0 15px;
						}

						h5 {
							color: #424242;
							font-size: 15px;
							font-family: Barlow Condensed, sans-serif;
						}

						.prices {
							.rentRate {
								color: var(--Darkgreen);
								font-size: 18px;
								font-weight: 400;

								s .discount-price {
									color: var(--Primarycolor);
								}

								.price-bl {
									strong {
										color: var(--Primarycolor);
									}
								}

							}
						}
					}
				}
			}
		}
	}

	.map-section {

		.affix {
			top: 150px;
			width: 387.5px;
			position: fixed !important;
		}

		.leaflet-popup-content {
			width: 290px !important;
			margin: 0;
			padding: 15px 15px 0 15px;

			@media all and (min-width: 768px) and (max-width: 991px) {
				width: 150px !important;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				width: 200px !important;
			}

			@media all and (min-width: 1200px) and (max-width: 1350px) {
				width: 220px !important;
			}
		}
	}

	.map-pane {
		@media (min-width:768px) {
			height: 80vh;
		}

		>div {
			height: 100%;
		}

		.header {
			height: auto;
		}

		.flex {
			height: 100%;
		}
	}

	@media (max-width:767px) {
		.search-list-section {
			.result-pane {
				.result-header {
					h3 {
						margin: 30px 0 10px 0;
						font-size: 1.875rem;

						small {
							margin: 10px 0 0 0;
						}
					}
				}
			}
		}

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}

		.map-section {
			#sidebar {
				top: unset !important;
				margin: 30px 0 0 0;
				width: 100% !important;
				position: relative !important;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			height: 100%;
			z-index: 999;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			width: 100%;
			max-width: 100%;

			.more-btn-panel {
				display: none;
			}
		}

		.results {
			.srow {
				@mixin flexlayout row;

				.info-col {
					width: 60%;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}
	}

	.search-main {
		padding: 50px 0;
		display: block;

		.container {
			width: 100%;
			max-width: 1700px;
		}
	}
}

.pagination-panel {
	.pagination {
		li {
			a {
				border: 1px solid var(--Primarycolor);
			}
		}

		li.active {
			a {
				background: var(--Primarycolor);
				color: #fff;
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 1199px) {
	.vo-search-body {
		.map-section {
			.affix {
				width: 20%;
			}
		}

		.search-list-section {
			.result-pane {
				.results {
					.srow {
						.info-col {
							padding: 10px 10px 10px;

							h4 {
								font-size: 24px;
								line-height: 30px;
							}
						}
					}
				}
			}
		}

		.search-bar {
			.panel {
				padding: 10px 5px 10px;
			}
		}
	}
}

@media all and (min-width: 1200px) and (max-width: 1700px) {
	.vo-search-body {
		.map-section {
			.affix {
				width: 22%;
			}
		}
	}
}