@import "fonts.css";

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.bottom_kon_box .form-section {
	display: block;
	margin: 0 auto;
}

.p-0-contact {
	padding: 0 !important;
}

.flex {
	@mixin flex;
}

.mb-20 {
	margin-bottom: 20px;

	&.seprator {
		width: 100%;
	}
}

@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}

body {
	font-family: 'Barlow Condensed', sans-serif;
	font-size: 1.05em;
	line-height: 1.1em;
}

html {
	font-size: 1.05em;
}


h1,
h2,
p {
	font-family: 'EB Garamond', serif;
}

.gb-td {
	label {
		white-space: pre;
	}
}

.static-view li {
	font-family: 'EB Garamond', serif;
	font-size: 18px;
	line-height: 24px;
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}

.btn-voucher {
	background-color: #a1c611;
	color: #FFF;
	font-size: 17px;
	border: 1px solid #fff;
	//box-shadow: 0 1px 1px #eee;

	&:hover {
		background-color: #7b970f;
		color: #fff;
		border: 1px solid #fff;
	}
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.cust-select {
	position: relative;

	select {
		cursor: pointer;
	}

	&:after {
		background-color: var(--white);
		width: 15px;
		height: 10px;
		position: absolute;
		content: "";
		right: 0;
		top: 13px;
	}
}

.datepicker-trigger {

	.asd__day-button {
		pointer-events: auto;
	}


	.asd__day-button:disabled,
	.asd__day-button[disabled] {
		pointer-events: none;
	}

	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1;
		color: #fff !important;
	}

	.asd__day--disabled {
		opacity: 1;
		color: #817f7f;
	}

	.asd__day--empty {
		opacity: 0.5;
	}

	.asd__day--arrival:not(.asd__day--disabled) {
		background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1;

		&:hover {
			background: var(--selected) !important;
			color: #fff !important;
		}
	}

	.asd__day--arrival {
		background: linear-gradient(135deg, var(--disabled) 50%, var(--not_available) 50%) !important;
		opacity: 1;
		color: #fff !important;
	}

	.asd__day--departure:not(.asd__day--disabled) {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%);
		opacity: 1;
		color: #fff !important;

		&:hover {
			background: var(--selected) !important;
			color: #fff !important;
		}
	}

	.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--disabled) 50%);
		opacity: 1;
	}

	.asd__day--disabled.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--disabled) 50%) !important;
		color: #fff !important;
	}

	.asd__day--disabled:not(.asd__day--departure, .asd__day--arrival, .asd__day--not-available, .asd__day--selected, .asd__selected-date-one, .asd__day--in-range, .asd__selected-date-two) {
		background: var(--disabled) !important;
		color: #fff !important;
	}

	.asd__day--enabled:not(.asd__day--departure, .asd__day--arrival, .asd__day--not-available, .asd__day--disabled, .asd__day--selected, .asd__selected-date-one, .asd__day--in-range, .asd__selected-date-two) {
		background-color: var(--available) !important;
		color: #fff !important;

		&:hover {
			background-color: var(--selected) !important;
			color: #fff !important;
		}
	}


	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
		color: #e6e2e2 !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
		color: #e6e2e2 !important;
	}

	/*

	.asd__day--disabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: var(--disabled) !important;
	} */

	/*	.asd__day--enabled {
		background-color: var(--available) !important;
		color: var(--text_color) !important;


		&.asd__day--disabled {
			background-color: var(--disabled) !important;
			color: #817f7f !important;
			text-decoration: underline;
			opacity: 1;

			&.asd__day--selected {
				background-color: var(--selected) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
		}
	} */



	.asd__day--selected {
		background: var(--selected) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;

		/*Arrow display css start*/
		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			}
		}

		/*Arrow display css end*/
	}


}


.asd__month-table {
	.arrow {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 5px 5px 0;
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		border-top-color: #000 !important;
	}

	.asd__week {
		display: flex;
	}
}

/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.btn-primary {
	background: var(--Primarycolor);
	border: 2px solid var(--Primarycolor);
	border-radius: 0;
	font-size: 15px;
	height: auto;
	padding: 8px 15px;

	&:hover {
		color: var(--Primarycolor) !important;
		background: #fff !important;
		border: 2px solid var(--Primarycolor) !important;
	}
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--Primarycolor) !important;
	border-color: var(--Primarycolor) !important;
	outline: none;
}

.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
}

.checkbox label:after {
	outline: none;
}

.bg-similar {
	padding: 50px 0;
	background-color: #f5f5f5;

	.unit-content {
		background-color: var(--white) !important;
	}
}

.mod_breadcrumb {
	width: 100%;
	min-height: 45px;
	margin: 0 auto;
	background-color: #EFEFEF;
	border-top: 1px solid #fff;
	display: flex;
	padding: 13px 0;

	.container {
		width: 100%;
		padding: 0 15px;
		max-width: 1700px;
	}

	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		width: 100%;
		list-style: none;

		>li+li:before {
			content: "\f101";
			font-family: Font Awesome\ 5 Free !important;
			font-size: 10px;
			opacity: 1;
			z-index: 1;
			position: relative;
			font-weight: 600;
			padding: 0 8px;
			color: #777;
		}

		li {
			margin: 0 0 5px 0;
			font-size: 14px;
			position: relative;
			display: flex;
			align-items: center;

			i {
				margin: 0 5px 0 0;
			}

			a {
				color: #424242;
			}
		}
	}
}

.upper {
	text-transform: uppercase;
}

#back_to_top {
	position: fixed;
	bottom: 35px;
	right: 35px;
	z-index: 5;
	background-color: #efefef !important;
	font-size: 32px;
	text-align: center;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
	border-radius: 3px;
	width: 60px;
	height: 60px;
	color: #424242;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 60px;

	i {}

	@media (max-width:992px) {
		bottom: 55px;
		right: 25px;
		height: 45px;
		width: 60px;

	}
}

.hide-mob {
	display: none;

	@media (min-width:993px) {
		display: block;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.asd__mobile-close {
	background-color: var(--black);
	color: var(--white);
	width: 36px;
	border-radius: 5px;
	margin-right: 10px;
}


#back_to_top:hover {
	background-color: #DDF;
	color: #000;
}

.form-group {
	.checkbox {
		label {
			&:before {
				top: 1px;
			}
		}
	}
}


@media (max-width: 991px) {
	#back_to_top {
		display: none !important;
	}
}

a,
a:focus,
.btn:focus,
.btn,
button,
button:focus,
input:focus,
input {
	outline: none;
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
	background-image: url(RESOURCE/img/icons.png) !imortant;
	background-repeat: no-repeat;
	border: none !important;
	text-decoration: none !important;
	cursor: pointer;
	width: 50px;
	height: 50px;
	top: 0;
}

.mandatory {
	color: #ff6363;
	display: inline;
	padding-left: 2px;
}

.p-relative {
	position: relative;
}

/*Static page image zoom function - START*/
#imgLageplan {
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;
	display: block;
	margin-left: auto;
	margin-right: auto
}

#imgLageplan:hover {
	opacity: 0.7;
}

/* Modal Content (image) */
.img-modal-content {
	margin: auto;
	display: block;
	max-width: 65%;
	padding: 20px;
}

/* Add Animation */
.modal-content,
.img-modal-content,
#caption {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}

.modal-content,
#caption {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}

.out {
	animation-name: zoom-out;
	animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(1)
	}

	to {
		-webkit-transform: scale(2)
	}
}

@keyframes zoom {
	from {
		transform: scale(0.4)
	}

	to {
		transform: scale(1)
	}
}

@keyframes zoom-out {
	from {
		transform: scale(1)
	}

	to {
		transform: scale(0)
	}
}

.box {
	.close {
		position: absolute;
		background: #fff;
		opacity: 0.7;
		color: #000;
		width: 35px;
		height: 35px;
		top: 15px;
		right: 35px;
		border-radius: 100px;
		font-size: 15px;
		font-weight: bold;

		&:hover {
			opacity: 1;
		}
	}
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
}

/*Static page image zoom function - END*/


.mt-2 {
	margin-top: 0.5rem;
}