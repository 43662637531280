/* eb-garamond-regular - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-500 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-500.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-500.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-600 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* eb-garamond-700 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-800 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 800;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-800.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-800.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-italic - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: italic;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-500italic - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: italic;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-500italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-500italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-600italic - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: italic;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-600italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-600italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-700italic - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: italic;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-700italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-700italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* eb-garamond-800italic - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: italic;
	font-weight: 800;
	src: local(''),
		url('RESOURCE/fonts/eb-garamond-v24-latin-800italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/eb-garamond-v24-latin-800italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-100 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 100;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-100.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-100.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-condensed-100italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 100;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-100italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-100italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-200 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 200;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-200.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-200.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-200italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 200;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-200italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-200italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-300italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-300italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-300italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-500 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-500.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-500.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-500italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-500italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-500italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-600italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-600italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-600italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-700italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-700italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-700italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-800 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 800;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-800.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-800.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-800italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 800;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-800italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-800italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-900 - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 900;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-900.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-900.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}

/* barlow-condensed-900italic - latin */
@font-face {
	font-family: 'Barlow Condensed';
	font-style: italic;
	font-weight: 900;
	src: local(''),
		url('RESOURCE/fonts/barlow-condensed-v11-latin-900italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-condensed-v11-latin-900italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

	font-display: swap;
}