.booking-view {
	margin-top: 140px;

	.page-header {
		margin: 0 0 20px 0;

		h1 {
			font-size: 30px;
			margin: 0 0 10px 0;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
		}
	}

	.confirmation {
		.fa-check {
			color: var(--Primarycolor) !important;
		}
	}

	.cart-details {
		h4 {
			font-size: 24px;
			margin: 0 0 15px 0;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 400;
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
				color: var(--Darkgreen);
				margin-left: 0;
				font-size: 18px;
			}
		}
	}

	.price-table {
		.btn-warning {
			background-color: var(--Primarycolor);
			color: #fff !important;
			border: 1px solid var(--Primarycolor);
		}
	}

	.pd-form {
		label {
			font-weight: 400;
			text-align: left;
			font-size: 18px;
			color: var(--Darkgreen)
		}

		label.lrequired {

			text-align: left;
		}

	}

	.step1 {
		.form-control {
			border-radius: 0;
			height: 40px;
			font-size: 18px;
		}

		textarea {
			height: 90px !important;
		}
	}

	.final-col {
		p {
			font-weight: 400;
			text-align: left;
			font-size: 18px;
			color: var(--Darkgreen);
		}

		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 400;
				text-align: left;
				font-size: 18px;
				line-height: 18px;
				color: var(--Darkgreen);
			}

			.doc-box {
				a {
					position: relative;
					top: 3px;
				}
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}

	.final-col {
		.btn-primary {
			text-align: center;
			border-radius: 2px;
			line-height: 38px;
			padding: 8px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 48px;
			font-size: 18px;
			letter-spacing: 0.05rem;

		}

		.btn-default {
			text-align: center;
			background-color: #fff;
			border: 1px solid var(--Themelight);
			border-radius: 2px;
			line-height: 38px;
			color: var(--Themelight) !important;
			padding: 8px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 48px;
			font-size: 18px;
			letter-spacing: 0.05rem;

			&:hover {
				color: var(--Reetlandred) !important;
				border: 1px solid var(--Reetlandred);
			}
		}
	}

	.checkbox {
		a {
			color: var(--Primarycolor);
			position: relative;
			top: 2px;
		}
	}

	.box.layout-column {
		.body {
			.mm-section {
				h3 {
					font-weight: 500;
					letter-spacing: .5px;
					font-size: 20px;
					line-height: 1.1em;
					margin: 0;
					padding: 0 0 15px;
					display: -webkit-box;
					display: flex;
					width: 100%;
					color: #1e5a50;
					color: var(--Darkgreen);
				}

				p {
					color: #333;
				}
			}
		}
	}
}